.slideshowContainer {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    .ban-button {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 48px;
        color: red;
        cursor: pointer;
    }
    .approve-button {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 48px;
        color: greenyellow;
        cursor: pointer;
    }

    .next-button {
        position: absolute;
        cursor: pointer;
        bottom: 50%;
        right: 0px;
        font-size: 40px;
        color: greenyellow;
    }

    .back-button {
        cursor: pointer;
        position: absolute;
        bottom: 50%;
        left: 0px;
        font-size: 48px;
    }

    .slideshowImage {
        max-width: 100vw;
        max-height: 100vh;
        height: 100vh;
        width: auto;
    }
}