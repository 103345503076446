@page {
    size: A4;
}
@font-face {
    font-family: Flareserif;
    src: url('./assets/fonts/Flareserif\ 821\ Bold.otf');
}
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap');
* {
    
}
/* orange and navy */
:root {
    /* --primary-color: rgb(255, 197, 89);
    --secondary-color: #1b2631; */
    --primary-color: #b3ccb6;
    --secondary-color: #131720;
}

.flareFont {
    font-family: 'Flareserif';
}
mark {
    color: var(--secondary-color);
    background-color: var(--primary-color);
    padding: 0em 0.15em;
}
.invert-colors {
    color: var(--secondary-color);
    background-color: var(--primary-color);
}
.aws-skill {
    grid-column-end: span 2;
}
.container {
    border-bottom: 5px solid var(--primary-color);
    color: var(--primary-color);
    background-color: var(--secondary-color);
    display: grid;
    max-width: 8.25in;
    width: 8.25in;
    /* height: 23.5in; */
    max-height: 23.5in;
    overflow-y: visible;    
    grid-template-columns: 22% 1fr;
    grid-template-rows: auto auto auto auto auto;
    align-content: stretch;
    column-gap: 1rem;
    row-gap: 1rem;
    /* background-image: url('./assets/images/yep\ yep\ orange.png'); */
    background-repeat: no-repeat;
    background-position: right 32%;
    background-size: 15%;
    font-family: 'Ubuntu Mono', monospace;
    font-size: 1.1rem;
}
.resume-background {
    background-size: 15%;
    background-color: var(--secondary-color);
    height: 23.5in;
    max-height: 23.5in;
    max-width: 8.25in;
    width: 8.25in;
}
.experience {
    display: grid;
    grid-template-columns: 22% 1fr;
}
.resume-header {
    grid-column-end: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 1em;
    padding-right: 1em;
}
.doublewide {
    grid-column-end: span 2;
}
.skills {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.section-title {
    text-align: right;
    font-family: 'Flareserif';
    font-weight: bold;
    font-size: larger;
    
}

.school-name {
    font-family: 'Flareserif';
    font-weight: bold;
    font-size: large;
    /* grid-column-end: span 2; */

}
.job-company {
    font-family: 'Flareserif';
    font-weight: bold;
    font-size: large;
    text-align: right;
    /* grid-column-end: span 2; */

}

.job-company-description {
padding-left: 20px;
}
.education {
    padding-bottom: 1em;
}
.contact-info {
    padding-top: .83em;
    text-align: right;
}
.job {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    /* padding-bottom: 1em; */
    padding-right: 1em;
    padding-left: 1em;
}
.job-time {
    text-align: right;
}
.job-title {
    font-size: large;
    font-weight: bold;
    /* padding-bottom: .5em; */
}
.job-info {
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    justify-content: space-between;
    /* padding-bottom: 1em; */
    padding-right: 1em;
    padding-left: 1em;
}
ul.job-details {
    list-style-type: none;
    /* padding-left: 0; */
    grid-column-end: span 2;
    /* margin-top: 0.25em; */
}
li.job-detail {
    padding-bottom: .1em;
}
.job-detail {
    display: list-item;
    list-style-position: outside;
    list-style-type: '+ ';
}
.school-info {
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    justify-content: space-between;
    /* padding-bottom: 1em; */
    padding-right: 1em;
    /* padding-left: 1em; */
}
.attributes .job-detail {
    list-style-position: inside;
}

a {
    /* no link style */
    /* text-decoration: none; */
    color: var(--secondary-color);

}
.material-symbols-outlined {
    vertical-align: middle;
}
