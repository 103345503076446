* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: rgb(27, 38, 49);
}
.modal-container {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: 5em;
}
.modal-button-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: space-between;
}
.mobile-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}